import React from "react";
import Cuestionarios from "../../widgets/Cuestionarios";
import CuestionarioController from "./CuestionarioController";
import Preguntas from "../../widgets/Preguntas";
import { connect } from "react-redux";


function Dashboard({ cuestionario }) {
  if (cuestionario != null) {   
    return (
      <>
       <CuestionarioController cuestionario={cuestionario}/>
      </>
    )
  } else {
    return (
      <>
        <div className="row">
          <div className="col-xl-4">
            < Cuestionarios />

          </div>
          <div className="col-xl-4">
            < Preguntas />
          </div>

        </div>


      </>
    );
  }
} 

function mapStateToProps(state) {
  return { cuestionario: state.cuestionario };
} 

export default connect(mapStateToProps)(Dashboard);
