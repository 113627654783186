import React, { Component } from 'react';
import { connect } from "react-redux";
import PreguntaOpciones from "./PreguntaOpciones";

export class Preguntas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      preguntas: [],
      cuestionario: null
    }
  }

  componentDidMount() {
    var cuestionario = "";
    if (this.props.cuestionario) {
      this.setState(
        {cuestionario: this.props.cuestionario}
      );
      cuestionario = this.props.cuestionario.dblCuestionario;
      console.log(this.props.cuestionario);
    }
    var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/getpreguntas";
    if (cuestionario != "") {
      var data = {
        dblCuestionario: cuestionario
      };
      data = JSON.stringify(data);
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: data, // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            this.setState({
              isLoaded: true,
              preguntas: result[0],
            });
          },
          // Nota: es importante manejar errores aqu� y no en 
          // un bloque catch() para que no interceptemos errores
          // de errores reales en los componentes.
          (error) => {
            console.log("Ocurrio un error al procesar la url");
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    }
  }

  render() {
    const { error, isLoaded, preguntas, cuestionario } = this.state;

    if (!isLoaded) {
      return (
        <h4></h4>
      )
    } else {
      return (
        <>
          <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      { cuestionario.strNombre }
                    </h3>
                  </div>
                </div>
                <div className="kt-portlet__body">

          <div className="kt-widget4">
          {
            preguntas.map((pregunta, index) => {
              return (
              
              <div className="kt-widget4__item" key="{pregunta.dblPregunta}">
                <div className="kt-widget4__info ">
                  <p className="kt-widget4__text">{ pregunta.strPregunta }</p>
                  
                </div>
              </div>
              )
            }
            )
          }
          </div>
          </div>
          </div>

        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    cuestionario: state.cuestionario,
    user: state.auth['user']
  }
}

export default connect(mapStateToProps)(Preguntas)
