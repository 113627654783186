import React, { useState, useEffect } from "react";
import Cuestionario from "./components/Cuestionario/Cuestionario";


export default function CuestionarioController({ cuestionario }) {
  const [isLoaded, setisLoaded] = useState(false);
  const [preguntas, setPreguntas] = useState({});

  var form = {};

  async function getCuestionario(dblCuestionario){
    var preguntas = [];
    console.log("entre al getCuestionario" + dblCuestionario);
    var result =  getPreguntas(dblCuestionario);
    var resultOpciones = "";
    result.then((res) => {
      res[0].map((p, i) => {
        let question = {};
        if (p.intObligatorio == 1) {
          question.isRequired = true;
        }
        question.choices = [];
        resultOpciones = ProcesarOpciones(p.dblPregunta);
        question.choices = resultOpciones;
        question.type = p.strCodigoTipo;
        // question.choices = ["Brown Bear", "Grizzly Bear", "Black Bear", "Polar Bear"];
        if(p.intTipoInput == 3){    
          console.log(p); 
          var respm = getPreguntasMatriz(p.dblPregunta)
          respm.then((respm2) => {
            respm2.map((pm, i) => {
              let data_pm = {};
              data_pm.isRequired = question.isRequired;
              data_pm.choices = question.choices;
              data_pm.name = "pp-" + p.intTipoInput + "-" + p.dblPregunta + "-" + pm.dblPreguntaMatriz;
              data_pm.type = question.type;
              data_pm.title = pm.strPreguntaMatriz;
              preguntas.push(data_pm);
            })
          })
        } else { 
          question.name = "pp-" + p.intTipoInput + "-" + p.dblPregunta;
          question.title = p.strPregunta;
          
          preguntas.push(question);
        }
      })
      setPreguntas(preguntas);
      setTimeout(function(){ 
        setisLoaded(true);
    }, 1300);
    })   
  }

  async function getPreguntas(dblCuestionario){
    var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/getpreguntas";
    var data = { dblCuestionario: dblCuestionario };
    data = JSON.stringify(data);
    let response = fetch(url, {
      method: 'POST', // or 'PUT'
      body: data, // data can be `string` or {object}!
      headers: { 'Content-Type': 'application/json' }
    })
    let result = await response.then(res => res.json());
     return result;
  }

  async function getPreguntasMatriz(dblPregunta){
    var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/getmatrizpreguntas";
    var data = { dblPregunta: dblPregunta };
    data = JSON.stringify(data);
    let response = fetch(url, {
      method: 'POST', // or 'PUT'
      body: data, // data can be `string` or {object}!
      headers: { 'Content-Type': 'application/json' }
    })
    let result = await response.then(res => res.json());
     return result;
  }

  function ProcesarOpciones(dblPregunta){
    var promesa = getOpciones(dblPregunta);
    var resultado  = [];

     promesa.then((res) => {
      res[0].map((r, i) => {
        resultado.push(
          {text: r.strOpcion,
            value: r.dblPreguntaOpcion} );
      });
    });
    return resultado;
  }

  async function getOpciones(dblPregunta){
    var data = { dblPregunta: dblPregunta };
    var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/getopcionespregunta";
    data = JSON.stringify(data);
    let response = fetch(url, {
      method: 'POST', // or 'PUT'
      body: data, // data can be `string` or {object}!
      headers: { 'Content-Type': 'application/json' }
    })
    let result = await response.then(res => res.json());
     return result;
  }

  useEffect(() => {
    getCuestionario(cuestionario.dblCuestionario);
  }, []);

    if (isLoaded) {
      form.preguntas = preguntas;

      return (
        // <h3>s</h3>
        <Cuestionario
          onFinish={answers => {
            console.log(answers)
          }}
          form={form}
          idCuestionario={cuestionario.dblCuestionarioEmpleado}
        />
      )
  }
    return (
      <>
        <h3 className="hms-message-full">Cargando el cuestionario...  </h3>
      </>
    )
    
      
  }

