/*
 * action types
 */

export const CUESTIONARIO_SELECCIONADO = 'CUESTIONARIO_SELECCIONADO'

/*
* other constants
*/

// Nothing to do here...

/*
* action creators
*/

export const contestarCuestionario = (cuestionario) => {
  return {
    type: CUESTIONARIO_SELECCIONADO,
    payload: cuestionario
  }
}