import React, { Component } from "react";
import { connect } from "react-redux";
import  Preguntas  from "./Preguntas";

class CuestionariosDetail extends Component{
  
render(){

  if(!this.props.cuestionario){
    return (<h3>No hay cuestionario seleccionado...</h3>);
  } else {
    return (
      <div>
      <div>
          <Preguntas />
      </div>
    </div>
  )
}
}
}



function mapStateToProps(state){
  return {
    cuestionario: state.cuestionario
  }
}

export default connect(mapStateToProps)(CuestionariosDetail);