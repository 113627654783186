/* eslint-disable jsx-a11y/anchor-has-content,no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Notice from "../partials/content/Notice";
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { contestarCuestionario } from './actions/actionsCuestionarios';
import CuestionariosDetail from "./CuestionariosDetail";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Modal } from "@material-ui/core";

  // function rand() {
  //   return Math.round(Math.random() * 20) - 10;
  // }

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {  
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 500,
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none"
  }
}));

function SimpleModal(props) {
  
  const [open, setOpen] = React.useState(props.deberiaMostrar);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [tipo2, setTipo2] = React.useState(props.tipo);
  // console.log(aspirante);
  var strNombre = ''
  var strNombre = props.aspirante.name;
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (st) => {
    setOpen(st);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  var texto = "";
  var texto2 = "";
  var titulo = "";
  if(tipo2 === 1){
    titulo = "¡Bienvenido!"
    texto =  
    "Hola " + strNombre + ".";
    texto2 = `
    
    A continuación, realizarás el llenado de unas pruebas, las cuales requerimos que lo contestes de la forma más honesta posible. Éxito!!`;
  } else {
    titulo = "De antemano, gracias por el tiempo brindado";
    texto = "Estás en la parte final del proceso, pronto tendrás respuesta de nosotros.";
  }

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
        <Typography variant="h6" id="modal-title">
            {titulo}
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
          { texto }
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
          { texto2 }
          </Typography>
        <Button  onClick={handleClose} variant="contained" color="primary">Cerrar</Button>
        </div>
      </Modal>
    </div>
  );
}

class Cuestionarios extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      deberiaMostrar: true,
      tipoModal: 1,
      isLoaded: false,
      items: [],
      cuestionario: null
    }

  }

  componentDidMount() {
    this.props.contestarCuestionario(null);
    var dblEmpleado = this.props.user['custom:dblEmpleado'];
    var dblTipo = "2";
    var dblID = dblEmpleado;
    var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/getcuestionario";
    var data = {
      dblTipo: dblTipo,
      dblID: dblID
    };
    data = JSON.stringify(data);
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: data, // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          let contador = 0;
          result[0].map((v, i) => {
            if(v.dblFechaAplicacion !== null){
              contador++;
              this.setState({ deberiaMostrar: false });
            }
          })
          if(contador === result[0].length){
            this.setState({
              deberiaMostrar: true,
              tipoModal: 2
            })
          } 
          this.setState({
            isLoaded: true,
            items: result[0],
          });
        },
        // Nota: es importante manejar errores aqu� y no en 
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }



  render() {
    const { error, isLoaded, items, deberiaMostrar, tipoModal } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className={"row justify-content-center"}>
          <CircularProgress color="secondary" size={100} />
        </div>
      )
    } else {

      if (this.props.contestarCuestionario) {
        if (items.length == 0) {
          return <h3 className="hms-message-full">Sin cuestionarios asignados</h3>;
        } else {
          return (
            <>
            <SimpleModal aspirante={this.props.user}
            deberiaMostrar={deberiaMostrar}
            tipo={tipoModal}
             />
          
              <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">Cuestionarios</h3>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="kt-widget4">
                    {

                      items.map((value, index) => {
                        
                        return <div className="kt-widget4__item" key={value.dblCuestionarioEmpleado}>
                          <div className="kt-widget4__info ">
                            <a
                              className="kt-widget4__username"
                              href="https://keenthemes.com.my/metronic"
                            >

                            </a>
                            <a
                              className="kt-widget4__title"
                              href="https://keenthemes.com.my/metronic"
                            />
                            <p className="kt-widget4__text ">
                              {value.strNombre}
                            </p>
                          </div>
                          {
                            value.dblFechaAplicacion == null ?
                              <a className="btn btn-sm btn-label-brand"
                                onClick={() => this.props.contestarCuestionario(value)}
                              >  Responder
                          </a>
                              :
                              <a className="btn btn-sm btn-label-success"

                              >  Contestado
                         </a>
                          }
                        </div>
                      })
                    }

                  </div>
                </div>
              </div>
            </>
          );
        }
      } else {
        return (
          < CuestionariosDetail />
        );
      }
    }
  }
}

const mapStateToProps = ({ auth: { user } }) => (
  { user });




function matchDispatchToProps(dispatch) {
  return bindActionCreators({ contestarCuestionario: contestarCuestionario }, dispatch)
}



export default connect(mapStateToProps, matchDispatchToProps)(Cuestionarios);

