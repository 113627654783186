
export default function CuestionarioResp(idCuestionario, respuestas){
    setCuestionarioFinalizado(idCuestionario, 1);

  for (const key of Object.keys(respuestas)) {
    var data = {};
    let val = respuestas[key];
    let explode = key.split("-");
    let idPreguntaM = "";
    let tipoPregunta = explode[0];
    let tipoInput = explode[1];
    let idPregunta = explode[2];
    if(tipoPregunta == 3){  
       idPreguntaM = explode[3];
    }
    let dblOpcion = "";
    let strOpcion = "";
    switch (tipoInput) {
      case 2:
        dblOpcion = "";
        strOpcion = "";
        break;
      case "1":
      case "3":
      case "5":
      case "10":
      case "11":
        dblOpcion = val;
        strOpcion = "";
        break;
      case "4":
      case "6":
      case "7":
        dblOpcion = "";
        strOpcion = val;
        break;    
      default:
        break;
    }

    data = {
      varCuestionarioEmpleado: idCuestionario,
      varPregunta: idPregunta,
      varTipoInput: tipoInput,
      varOpcion: dblOpcion,
      varstrOpcion: strOpcion,
      varPreguntaMatriz: idPreguntaM
    };

    var promise = setRespuestaPregunta(data);
    promise.then((res) => {
      var dblRespuesta = res[0].lastid;
      if(tipoInput == 2){
        val.forEach( element => {
          setRespuestaMultiple(dblRespuesta, element)
        });
      }
    })
 

  }



async function setCuestionarioFinalizado(dblCuestionario, dblStatus){
  var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/setcuestionariofinalizado";
  var data = { dblCuestionarioEmpleado: dblCuestionario, dblStatus: dblStatus };
  data = JSON.stringify(data);
  let response = fetch(url, {
    method: 'POST', // or 'PUT'
    body: data, // data can be `string` or {object}!
    headers: { 'Content-Type': 'application/json' }
  })
  let result = await response.then(res => res.json());
   return result;
}

async function setRespuestaMultiple(dblRespuesta, dblOpcion){
  var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/setrespuestamultiple";
  var data = { dblOpcion: dblOpcion, dblRespuesta: dblRespuesta };
  data = JSON.stringify(data);
  let response = fetch(url, {
    method: 'POST', // or 'PUT'
    body: data, // data can be `string` or {object}!
    headers: { 'Content-Type': 'application/json' }
  })
  let result = await response.then(res => res.json());
   return result;
}



async function setRespuestaPregunta(dataOp){
  var url = "https://bz58m9625e.execute-api.us-east-2.amazonaws.com/produccion/cuestionarios/setrespuestapregunta";
  var data = dataOp;
  data = JSON.stringify(data);
  let response = fetch(url, {
    method: 'POST', // or 'PUT'
    body: data, // data can be `string` or {object}!
    headers: { 'Content-Type': 'application/json' }
  })
  let result = await response.then(res => res.json());
   return result;
}

}



