import React, { useState, useEffect, useRef } from "react"


import Cuestionarios from "../../../../widgets/Cuestionarios";

import CuestionarioPregunta from "../CuestionarioPregunta"
import QuestionContext from "../QuestionContext"
import CuestionarioResp from "./CuestionarioResp"
type Props = {
  form: any,
  onFinish?: Object => any,
  variant: "flat" | "paper",
  onQuestionChange?: (
    questionId: string,
    newValue: any,
    answers: Object
  ) => any,
  defaultAnswers?: Object,
  idCuestionario: any

}

export default function Cuestionario({
  form,
  onFinish,
  variant = "paper",
  onQuestionChange = () => null,
  defaultAnswers = {}, 
  idCuestionario
}: Props) {


  const [currentPage, setCurrentPage] = useState(0)
  const [answerMap, setAnswerMap] = useState(defaultAnswers)
  const [failingQuestions, changeFailingQuestions] = useState([])
  const surveyDiv = useRef(null)


  const [isEnd, setisEnd] = useState(false);
  const [currentQuestion, setcurrentQuestion] = useState(0);
  const [q, setPregunta] = useState({});
  const [isFilled, setisFilled] = useState(false);
  const [btnPreguntas, setbtnPreguntas] = useState(" ");

  const preguntas = form.preguntas || form.pages[currentPage].elements

  useEffect(() => {
    console.log(preguntas[currentQuestion].isRequired)
    if(preguntas[currentQuestion].isRequired == true){
      setbtnPreguntas("disabled")
    } else {
      setbtnPreguntas(" ")
    }
    setPregunta(preguntas[currentQuestion])
  }, [currentQuestion]);



  const finishHandler = () => {
    if (currentQuestion === preguntas.length - 1) {
      if(q.isRequired == true && !isFilled){ 
      } else {
        CuestionarioResp(idCuestionario, answerMap)
        setTimeout(() => {
          setisEnd(true);
        }, 1500);
      }
    }
  };


  const nextQuestionHandler = () => {
    if(q.isRequired == true && !isFilled){ 
    } else {
      setcurrentQuestion(currentQuestion + 1)
      setisFilled(false)
    }
  };

  

  if (isEnd) {
    return (
      <>
      <Cuestionarios />
      </>
    )
  } else {
    if (Object.keys(q).length === 0) {         
      return(
        <h4>...</h4>
      )
    } else {
    return (
      <>
        <h3 className="hms-text-center">Pregunta {currentQuestion + 1} de {preguntas.length}</h3>
        <div
          ref={surveyDiv}>
          <QuestionContext.Provider
            key={q.name}
            value={{
              error: (
                failingQuestions.find(fq => fq.question.name === q.name) || {}
              ).text,
              containerStyleType: variant
            }}
          >
            <CuestionarioPregunta

              question={{ ...q, defaultAnswer: defaultAnswers[q.name] }}
              onChangeAnswer={(newAnswer: any) => {
                const newAnswerMap = {
                  ...answerMap,
                  [q.name]: newAnswer
                }
                setAnswerMap(newAnswerMap)
                setisFilled(true)
                setbtnPreguntas(" ")
                onQuestionChange(q.name, newAnswer, newAnswerMap)
              }}
            />
          </QuestionContext.Provider>
        </div>
        {currentQuestion < preguntas.length - 1 && (
          <button
            className={"ui inverted button btn btn-primary btn-elevate kt-login__btn-primary hms-button-center " + btnPreguntas}
            onClick={nextQuestionHandler}
          >
            Siguiente
          </button>
        )}
        {/* //adding a finish button */}
        {currentQuestion === preguntas.length - 1 && (
          <button className="ui inverted button btn btn-success btn-elevate kt-login__btn-primary hms-button-center"
            onClick={finishHandler}
          >
            Finalizar
          </button>
        )}
      </>
    )
  }
}

}