export default {
  header: {
    self: {},
    items: [
      {
        title: "Cuestionarios",
        root: true,
        alignment: "left",
        page: "",
      },
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Cuestionarios",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "",
        bullet: "dot"
      }
      
        ]
      }
    
  
};
